import React from 'react';

export default function Content({ children }) {
	return (
		<div id='Content' className='col-md-12 flex-md-grow-1 d-flex'>
			<div className='container px-md-0 p-0 flex-md-grow-1 d-flex'>
				<div className='row mx-auto flex-md-grow-1 d-flex w-100'>
					{/* Each page/section should be constructed within a .container or .container-fluid class! */}
                    <section className='container py-3 px-3 px-md-0 flex-md-grow-1 flex-column mb-3 justify-content-around'>
                        {children}
                    </section>
				</div>
			</div>
		</div>
	);
}
