import React from 'react';
import {
    FaGithubSquare,
    FaLinkedin,
    FaInstagram,
    FaTwitterSquare,
    FaDeviantart
} from 'react-icons/fa';

export default function Footer() {
	return (
    <footer
      id="Footer"
      className="col-md-12 d-flex d-md-flex justify-content-center p-3 border border-left-0 border-right-0 border-bottom-0 w-100 mb-0 bg-light mt-auto"
    >
      <ul className="navbar navbar-nav flex-row justify-content-around align-items-center align-self-center p-0 mx-auto">
        <li className="nav-item">
          <a
            className="nav-link text-secondary"
            href="https://github.com/MauriceWebb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithubSquare size="2.5em" />
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-secondary hover-darkcyan"
            href="https://www.linkedin.com/in/mauricejwebb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size="2.5em" />
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-secondary hover-instarainbow"
            href="https://www.instagram.com/webofwebb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size="2.5em" />
          </a>
          <svg
		  	// This svg element is just to create a gradient fill for the instagram svg icon. This work around can be referenced here: https://fvsch.com/svg-gradient-fill/
            style={{
              width: 0,
              height: 0,
              position: `absolute`
            }}
            aria-hidden="true"
            focusable="false"
          >
            <linearGradient id="instagram-icon-gradient" x2="1" y2="1">
              <stop offset="0%" stopColor="#fdf497" />
              <stop offset="5%" stopColor="#fdf497" />
              <stop offset="45%" stopColor="#fd5949" />
              <stop offset="60%" stopColor="#d6249f" />
              <stop offset="90%" stopColor="#285aeb" />
            </linearGradient>
          </svg>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-secondary hover-dodgerblue"
            href="https://www.twitter.com/webofwebb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitterSquare size="2.5em" />
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-secondary hover-limegreen"
            href="https://www.deviantart.com/zeluht"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaDeviantart size="2.5em" />
          </a>
        </li>
      </ul>
    </footer>
  );
}
