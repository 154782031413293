/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import './layout.scss';

const Layout = ({ children }) => {
	return (
		<div id='App' className=''>
			<div className='container-fliud h-100'>
				<div
					id='AppContent'
					className='row h-100- m-0 p-md-3 d-flex justify-content-center align-items-center '
					style={{boxSizing: `border-box`}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default Layout;
