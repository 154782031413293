import React from 'react';

export default function Main({ children }) {
	return (
    <div id="Main" className="col-md-10 px-0 pl-md-3 h-100 d-flex">
      <div className="container-fluid flex-md-grow-1 d-flex p-0 overflow-hidden shadow">
        <div className="scroll-y row flex-md-grow-1 w-100 m-0 shadow bg-white position-relative d-flex flex-column d-md-block">
          {children}
        </div>
      </div>
    </div>
  );
}
