import React from "react";
import { Link } from "gatsby";

export default function Navigation() {
  return (
    <div
      id="Navigation"
      className="col-md-2 d-md-flex justify-content-center align-items-center shadow"
    >
      {/* Navigation Section */}
      <nav className="navbar navbar-expand-md navbar-dark flex-md-column align-items-center">
        <div className="col-md-12 d-flex justify-content-between">
          {/* Logo  */}
          <Link className="navbar-brand mx-md-auto" to="/">
            <strong>['] [,]</strong>
          </Link>
          {/* /Logo  */}
          {/* Responsive Navbar Toggler  */}
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#main_nav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* /Responsive Navbar Toggler  */}
        </div>
        {/* Responsive Vertical Navbar  */}
        <div
          className="col-md-12 collapse navbar-collapse justify-content-center my-md-3"
          id="main_nav"
        >
          <ul className="navbar-nav flex-md-column py-3">
            <li className="nav-item text-center">
              <Link activeClassName="active" className="nav-link" to="/">
                About<span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item text-center">
              <Link activeClassName="active" className="nav-link" to="/resume">
                Resume
              </Link>
            </li>
            <li className="nav-item text-center">
              <Link
                activeClassName="active"
                className="nav-link"
                to="/portfolio"
              >
                Portfolio
              </Link>
            </li>
            <li className="nav-item text-center">
              <Link
                activeClassName="active"
                className="nav-link disabled-"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li className="nav-item text-center">
              <Link activeClassName="active" className="nav-link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        {/* /Responsive Vertical Navbar  */}
      </nav>
      {/* End Navigation Section */}
    </div>
  );
}
